import React, { useEffect, useState } from "react";
import {
  useChaufage,
  useIsolationComble,
  useIsolationMur,
  useIsolationPlanchar,
} from "../api/calculeApi";
// import Dropdown from "./Dropdown";

export default function ChauffageForm({ pacChauffePoele, setPacChauffePoele }) {
  // const [formData, setFormData] = useState({
  //   system: [],
  //   isolationComb: [],
  //   isolationPlanchar: [],
  // });
  const [completed, setCompleted] = React.useState(0);

  const isolationMur = useIsolationMur();
  const chaufage = useChaufage();
  const isolationPlanchar = useIsolationPlanchar();
  const isolationComble = useIsolationComble();
  const isolationMurData = isolationMur?.data;
  const isolationPlancharData = isolationPlanchar?.data;
  const isolationCombleData = isolationComble?.data;

  const handleChange = (e) => {
    setPacChauffePoele({
      ...pacChauffePoele,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form className="w-full px-8 pt-6 pb-2 mb-4">
      <div className="flex flex-wrap mb-2 -mx-3">
        <div className="w-full px-3 mb-2 text-center md:w md:mb-0">
          <label
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
            className="block mb-1 ml-2 font-bold text-gray-700"
            htmlFor="Nom"
          >
            PAC AIR/EAU OU AIR/AIR
          </label>
          <div className="flex justify-around">
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="pac"
              name="pac"
              type="text"
              value={pacChauffePoele.pac}
              placeholder="QT"
              onChange={(e) => handleChange(e)}
            />
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="pacCout"
              name="pacCout"
              type="text"
              placeholder="P.U €"
              value={
                pacChauffePoele.pacCout === 0 ? "" : pacChauffePoele.pacCout
              }
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-2 -mx-3">
        <div className="w-full px-3 mb-2 text-center md:w md:mb-0">
          <label
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
            className="block mb-1 ml-2 font-bold text-gray-700"
            htmlFor="Nom"
          >
            CHAUFFE-EAU <br></br> THERMODYNAMIQUE
          </label>
          <div className="flex justify-around">
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="chauffe"
              value={pacChauffePoele.chauffe}
              name="chauffe"
              type="text"
              placeholder="QT"
              onChange={(e) => handleChange(e)}
            />
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="chauffeCout"
              name="chauffeCout"
              type="text"
              placeholder="P.U €"
              value={
                pacChauffePoele.chauffeCout === 0
                  ? ""
                  : pacChauffePoele.chauffeCout
              }
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-2 -mx-3">
        <div className="w-full px-3 mb-2 text-center md:w md:mb-0">
          <label
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
            className="block mb-1 ml-2 font-bold text-gray-700"
            htmlFor="Nom"
          >
            POELE A GRANULÉS
          </label>
          <div className="flex justify-around">
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="poeleGrannule"
              name="poeleGrannule"
              value={pacChauffePoele.poeleGrannule}
              type="text"
              placeholder="QT"
              onChange={(e) => handleChange(e)}
            />
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="poeleGrannuleCout"
              name="poeleGrannuleCout"
              type="text"
              placeholder="P.U €"
              value={
                pacChauffePoele.poeleGrannuleCout === 0
                  ? ""
                  : pacChauffePoele.poeleGrannuleCout
              }
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
