import axios from "axios";

const axiosClient = axios.create({
  // baseURL: "http://localhost:8000/api",
  baseURL: "https://betechniques.fr/api/api",
  withCredentials: true,
  headers: {
    // Accept: ["application/json", "application/pdf"],
  },
});

export default axiosClient;
