import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CurrencyFormat from "react-currency-format";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export default function SimpleAccordion({
  inputValueMoblie,
  setInputValueMoblie,
  handleSubmitCefProjectMobile,
  result,
  isolationData,
  pacChauffePoele,
}) {
  return (
    <div>
      <Accordion
        classes={{ rounded: "1px" }}
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          marginBottom: "25px",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                fill: "rgba(0,187,244,1)",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
          >
            Scénario BBC
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTop: "rgba(112,112,112,1) 1px solid",
          }}
        >
          <ul className="">
            <div className="mb-2">
              <div
                className="flex justify-between px-6 py-2 text-xs text-gray-500 "
                style={{ position: "relative" }}
              >
                <span>
                  {" "}
                  <strong> Prime</strong>
                </span>

                <div
                  id="color_range"
                  style={{
                    position: "absolute",
                    height: "200px",
                    width: "15px",
                    background:
                      "linear-gradient(180deg, rgba(228,31,23,1) 0%, rgba(251,237,6,1) 100%)",
                    top: "0",
                    left: "50%",
                    transform: "translateX(-50%) scaleY(1)",
                  }}
                >
                  <svg
                    class="Icon_material-arrow-drop-up_bp"
                    viewBox="10.5 13.5 28.407 14.204"
                    style={{
                      overflow: "visible",
                      position: "absolute",
                      width: "28.407px",
                      height: "382.204px",
                      transform:
                        "translate(-207.296px, -234px) matrix(1,0,0,1,207.2964,430.9999) rotate(180deg)",
                      transformOrigin: "center",
                      left: "-6px",
                      top: "-184px",
                      fill: "#fbed06",
                    }}
                  >
                    <path
                      id="Icon_material-arrow-drop-up_bp"
                      d="M 10.5 27.7037353515625 L 24.70373344421387 13.5 L 38.907470703125 27.7037353515625 L 10.5 27.7037353515625 Z"
                    ></path>
                  </svg>
                </div>
                <svg
                  class="Rectangle_76"
                  style={{
                    position: "absolute",
                    overflow: "visible",
                    width: "23px",
                    height: "24px",
                    left: "62%",
                    top: "184px",
                    transform: "translateX(-50%) scaleY(1)",
                  }}
                >
                  <rect
                    style={{
                      fill: "transparent",
                      stroke: "rgba(251,237,6,1)",
                      strokeWidth: "1px",
                      strokeLinejoin: "miter",
                      strokeLinecap: "butt",
                      strokeMiterlimit: "4",
                      shapeRendering: "auto",
                    }}
                    id="Rectangle_76"
                    rx="6"
                    ry="6"
                    x="0"
                    y="0"
                    width="23"
                    height="24"
                  ></rect>
                </svg>
                <div
                  id="G"
                  style={{
                    left: "62%",
                    top: "-416px",
                    transform: "translateX(-50%) scaleY(1)",
                    position: "absolute",
                    overflow: "visible",
                    width: 16,
                    whiteSpace: "nowrap",
                    lineHeight: 44,
                    marginTop: "-12px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "rgba(225,10,25,1)",
                  }}
                >
                  <span>G</span>
                </div>
                <svg
                  class="Rectangle_76"
                  style={{
                    position: "absolute",
                    overflow: "visible",
                    width: "23px",
                    height: "24px",
                    left: "62%",
                    top: "0px",
                    transform: "translateX(-50%) scaleY(1)",
                  }}
                >
                  <rect
                    style={{
                      fill: "transparent",
                      stroke: "rgba(226,11,26,1)",
                      strokeWidth: "1px",
                      strokeLinejoin: "miter",
                      strokeLinecap: "butt",
                      strokeMiterlimit: "4",
                      shapeRendering: "auto",
                    }}
                    id="Rectangle_76"
                    rx="6"
                    ry="6"
                    x="0"
                    y="0"
                    width="23"
                    height="24"
                  ></rect>
                </svg>

                <div
                  id="D"
                  style={{
                    left: "62%",
                    top: "-231px",
                    transform: "translateX(-50%) scaleY(1)",
                    position: "absolute",
                    overflow: "visible",
                    width: 16,
                    whiteSpace: "nowrap",
                    lineHeight: 44,
                    marginTop: "-12px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "rgba(251,237,6,1)",
                  }}
                >
                  <span>D</span>
                </div>
                <span>
                  <CurrencyFormat
                    value={result?.amount1}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>Cef Initiale</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cef_initial}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span
                  style={{
                    display: "grid",
                    alignItems: "center !important",
                  }}
                >
                  <strong> Cef Projet</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cef_projet1}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span
                  style={{
                    display: "grid",
                    alignItems: "center !important",
                  }}
                >
                  <strong> CEP projet</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cep_projet1}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong> Shab</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.shab}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong> B</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.b1}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>CUMAC</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cumac1}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span
                  style={{
                    color: "rgba(79, 70, 229, 1)",
                  }}
                >
                  <strong>SC1: &nbsp;</strong>
                </span>
                <span
                  style={{
                    color: "rgba(79, 70, 229, 1)",
                  }}
                >
                  Scénario Standard: PAC / Ballon / 101 / 103
                </span>
              </div>
            </div>

            <div
              style={{
                borderTop: "1px solid #1b5fa8",
                marginTop: "1rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
              }}
            >
              <div
                className="flex justify-between px-6 py-2 text-xs text-gray-500"
                style={{ position: "relative" }}
              >
                <span>
                  <strong> Prime</strong>
                </span>
                <div
                  id="color_range"
                  style={{
                    position: "absolute",
                    height: "200px",
                    width: "15px",
                    background:
                      "linear-gradient(180deg, rgba(228,31,23,1) 0%, rgba(251,237,6,1) 50%, rgba(199,212,4,1) 100%)",
                    top: "0",
                    left: "50%",
                    transform: "translateX(-50%) scaleY(1)",
                  }}
                >
                  <svg
                    class="Icon_material-arrow-drop-up_bp"
                    viewBox="10.5 13.5 28.407 14.204"
                    style={{
                      overflow: "visible",
                      position: "absolute",
                      width: "28.407px",
                      height: "382.204px",
                      transform:
                        "translate(-207.296px, -234px) matrix(1,0,0,1,207.2964,430.9999) rotate(180deg)",
                      transformOrigin: "center",
                      left: "-6px",
                      top: "-184px",
                      fill: "rgba(199,212,4,1)",
                    }}
                  >
                    <path
                      id="Icon_material-arrow-drop-up_bp"
                      d="M 10.5 27.7037353515625 L 24.70373344421387 13.5 L 38.907470703125 27.7037353515625 L 10.5 27.7037353515625 Z"
                    ></path>
                  </svg>
                </div>
                <svg
                  class="Rectangle_76"
                  style={{
                    position: "absolute",
                    overflow: "visible",
                    width: "23px",
                    height: "24px",
                    left: "62%",
                    top: "184px",
                    transform: "translateX(-50%) scaleY(1)",
                  }}
                >
                  <rect
                    style={{
                      fill: "transparent",
                      stroke: "rgba(199,212,4,1)",
                      strokeWidth: "1px",
                      strokeLinejoin: "miter",
                      strokeLinecap: "butt",
                      strokeMiterlimit: "4",
                      shapeRendering: "auto",
                    }}
                    id="Rectangle_76"
                    rx="6"
                    ry="6"
                    x="0"
                    y="0"
                    width="23"
                    height="24"
                  ></rect>
                </svg>
                <div
                  id="G"
                  style={{
                    left: "62%",
                    top: "-416px",
                    transform: "translateX(-50%) scaleY(1)",
                    position: "absolute",
                    overflow: "visible",
                    width: 16,
                    whiteSpace: "nowrap",
                    lineHeight: 44,
                    marginTop: "-12px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "rgba(225,10,25,1)",
                  }}
                >
                  <span>G</span>
                </div>
                <svg
                  class="Rectangle_76"
                  style={{
                    position: "absolute",
                    overflow: "visible",
                    width: "23px",
                    height: "24px",
                    left: "62%",
                    top: "0px",
                    transform: "translateX(-50%) scaleY(1)",
                  }}
                >
                  <rect
                    style={{
                      fill: "transparent",
                      stroke: "rgba(226,11,26,1)",
                      strokeWidth: "1px",
                      strokeLinejoin: "miter",
                      strokeLinecap: "butt",
                      strokeMiterlimit: "4",
                      shapeRendering: "auto",
                    }}
                    id="Rectangle_76"
                    rx="6"
                    ry="6"
                    x="0"
                    y="0"
                    width="23"
                    height="24"
                  ></rect>
                </svg>

                <div
                  id="C"
                  style={{
                    left: "62%",
                    top: "-231px",
                    transform: "translateX(-50%) scaleY(1)",
                    position: "absolute",
                    overflow: "visible",
                    width: 16,
                    whiteSpace: "nowrap",
                    lineHeight: 44,
                    marginTop: "-12px",
                    textAlign: "left",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "rgba(199,212,4,1)",
                  }}
                >
                  <span>C</span>
                </div>
                <span>
                  <CurrencyFormat
                    value={result?.amount2}
                    thousandSeparator={true}
                    prefix={"€"}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>Cef Initiale</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cef_initial}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>Cef Projet</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cef_projet2}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span
                  style={{
                    display: "grid",
                    alignItems: "center !important",
                  }}
                >
                  <strong> CEP projet</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cep_projet2}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>Shab</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.shab}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>B</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.b2}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>
              <div className="flex justify-between px-6 py-2 text-xs text-gray-500 ">
                <span>
                  <strong>CUMAC</strong>
                </span>
                <span>
                  <CurrencyFormat
                    value={result?.cumac2}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </span>
              </div>

              <div className="flex content-center  px-6 py-2 text-xs text-gray-500">
                <span
                  style={{
                    color: "rgba(79, 70, 229, 1)",
                  }}
                >
                  <strong>SC2:&nbsp;</strong>
                </span>
                <span
                  style={{
                    marginLeft: "2px",
                    color: "rgba(79, 70, 229, 1)",
                  }}
                >
                  Scénario BBC: PAC / Ballon / 101 / 102
                </span>
              </div>
            </div>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ rounded: "1px" }}
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          marginBottom: "25px",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                fill: "rgba(0,187,244,1)",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
          >
            Cout Travaux Isolation
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTop: "rgba(112,112,112,1) 1px solid",
          }}
        >
          <div
            style={{
              border: "1px solid #1b5fa8",
            }}
          >
            <li className="flex justify-center px-6 py-2 text-xs text-gray-500 border-b">
              {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
              <strong>COUT TRAVAUX ISOLATION</strong>
            </li>
            <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span
                style={{
                  width: "66px",
                }}
              >
                {" "}
                <strong> ITE</strong>
              </span>
              <span>{isolationData?.ITE + " m²"}</span>
              {/* <CurrencyFormat */}
              {/* thousandSeparator={true} */}
              {/* displayType={"text"} */}
              {/* /> */}
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={isolationData?.ITECOUT}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.ITE}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span>
                <strong>RAMPANTS</strong>
              </span>
              <span>
                {/* <CurrencyFormat */}
                {isolationData?.RAMPANTS + " m²"}
                {/* thousandSeparator={true} */}
                {/* displayType={"text"} */}
                {/* /> */}
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={isolationData?.RAMPANTSCOUT}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.RAMPANTS}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span
                style={{
                  display: "grid",
                  alignItems: "center !important",
                  width: "66px",
                }}
              >
                <strong> COMBLES</strong>
              </span>

              <span>
                {/* <CurrencyFormat */}
                {isolationData?.COMBLES + " m²"}

                {/* thousandSeparator={true} */}
                {/* displayType={"text"} */}
                {/* /> */}
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={isolationData?.COMBLESCOUT}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.COMBLES}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ rounded: "1px" }}
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          marginBottom: "25px",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                fill: "rgba(0,187,244,1)",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
          >
            Cout Travaux Chauffage
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTop: "rgba(112,112,112,1) 1px solid",
          }}
        >
          <div
            style={{
              border: "1px solid #1b5fa8",
              marginTop: "1rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}
          >
            <li className="flex justify-center px-6 py-2 text-xs text-gray-500 border-b">
              {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
              <strong>COUT TRAVAUX CHAUFFAGE</strong>
            </li>
            <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span
                style={{
                  maxWidth: "120px",
                }}
              >
                {" "}
                <strong> PAC AIR/EAU OU AIR/AIR</strong>
              </span>
              <span>{pacChauffePoele?.pac}</span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.pacCout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.pacEauAir}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b ">
              <span
                style={{
                  maxWidth: "120px",
                }}
              >
                <strong>CHAUFFE-EAU THERMODYNAMIQUE</strong>
              </span>
              <span>{pacChauffePoele?.chauffe}</span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.chauffeCout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.chaufEauTHermodynamique}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span
                style={{
                  display: "grid",
                  alignItems: "center !important",
                }}
              >
                <strong> POELE A GRANNULÉ</strong>
              </span>

              <span>{pacChauffePoele?.poeleGrannule}</span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.poeleGrannuleCout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
              <span>
                <CurrencyFormat
                  prefix={"€"}
                  value={result?.PoeleAGrannule}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        classes={{ rounded: "1px" }}
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          marginBottom: "25px",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{
                fill: "rgba(0,187,244,1)",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
          >
            Cout Scénario
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderTop: "rgba(112,112,112,1) 1px solid",
          }}
        >
          <div
            style={{
              border: "1px solid #1b5fa8",
              marginTop: "1rem",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
            }}
          >
            <li className="flex justify-between px-6 py-2 text-xs text-gray-500 border-b">
              {/* <span>
            <strong>SC1: &nbsp;</strong>
          </span> */}
              <span
                style={{
                  display: "grid",
                  alignItems: "center !important",
                }}
              >
                <strong> SCENARIO</strong>
              </span>
              <span
                style={{
                  display: "grid",
                  alignItems: "center !important",
                }}
              >
                <strong> COUT TRAVAUX</strong>
              </span>
            </li>
            <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span style={{ maxWidth: "200px" }}>
                {" "}
                <strong> ITE + PAC + BALLON </strong>
              </span>
              <span>
                {/* <CurrencyFormat */}

                <CurrencyFormat
                  prefix={"€"}
                  value={result?.itePacBaloon.cout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span style={{ maxWidth: "200px" }}>
                <strong>ITE + PAC + BALLON + POELE</strong>
              </span>

              <span>
                {/* <CurrencyFormat */}

                <CurrencyFormat
                  prefix={"€"}
                  value={result?.itePacBaloonPoele.cout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span style={{ maxWidth: "200px" }}>
                <strong> RAMPANTS + PAC + BALLON + POELE</strong>
              </span>

              <span>
                {/* <CurrencyFormat */}

                <CurrencyFormat
                  prefix={"€"}
                  value={result?.ramponPacBaloonPoele.cout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
            <li className="flex items-center justify-between px-6 py-2 text-xs text-gray-500 border-b">
              <span style={{ maxWidth: "200px" }}>
                <strong> COMBLES + PAC + BALLON + POELE</strong>
              </span>

              <span>
                {/* <CurrencyFormat */}

                <CurrencyFormat
                  prefix={"€"}
                  value={result?.comblesPacBaloonPoele.cout}
                  thousandSeparator={true}
                  displayType={"text"}
                />
              </span>
            </li>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
