import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import AdjustIcon from "@mui/icons-material/Adjust";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";
import { StepsFunction } from "../components/Steps";
import { styled } from "@mui/material/styles";
import {
  ColorlibConnector,
  ColorlibStepIcon,
} from "../components/styled/Colorlib";
import "../components/styled/animation.css";
import { useCalculate } from "../api/calculeApi";
import CurrencyFormat from "react-currency-format";
import { Icon } from "@iconify/react";
import CustomTable from "../components/CustomTable";
import CustomList from "../components/CustomList";
import CustomListTwo from "../components/CustomListTwo";
import CustomDialog from "../components/CustomDialog";
import SimpleAccordion from "../components/SimpleAccordion";
export default function Main({ progress, setProgress }) {
  const [formData, setFormData] = useState({
    name: "",
    system: [],
    isolationComb: [],
    isolationPlanchar: [],
  });

  const [isolationData, setIsolationData] = React.useState({
    ITE: 0,
    ITECOUT: 0,
    RAMPANTS: 0,
    RAMPANTSCOUT: 0,
    COMBLES: 0,
    COMBLESCOUT: 0,
    TotalIte: 0,
    TotalRamprants: 0,
    TotalCombles: 0,
  });
  const [pacChauffePoele, setPacChauffePoele] = React.useState({
    pac: 1,
    pacCout: 0,
    chauffe: 1,
    chauffeCout: 0,
    poeleGrannule: 1,
    poeleGrannuleCout: 0,
    totalPac: 0,
    totalRampants: 0,
    TotalCombles: 0,
  });

  const [selectedOptionDropMain, setSelectedOptionDropMain] = React.useState({
    id: 1,
    title: "Précaire",
  });
  const [selectedOptionDropD, setSelectedOptionDropD] = React.useState({
    code: 1000,
    name: "Ain",
  });

  const [selectedOptionMiton, setSelectedOptionMiton] = React.useState({
    id: 1,
    title: "non",
  });
  const [selectedOptionMitonTypeFen, setSelectedOptionMitonTypeFen] =
    React.useState({
      id: 1,
      title: "Simple vitrage",
    });
  const [valorisation, setValorisation] = React.useState(6.8);
  const [customFormData, setcustomFormData] = React.useState({
    nom: "",
    prenom: "",
    surface: "",
  });

  const [selectedOptionIsolationMur, setSelectedOptionIsolationMur] =
    React.useState({ id: 1, title: "Aucun" });

  const [selectedOptionIsolationPlan, setSelectedOptionIsolationPlan] =
    React.useState({ id: 1, title: "Aucun" });

  const [selectedOptionIsolationCom, setSelectedOptionIsolationCom] =
    React.useState({ id: 1, title: "Aucun" });
  const [selectedOptionIChaufageCom, setSelectedOptionIChaufageCom] =
    React.useState({
      id: 1,
      title: "Fioul",
    });

  const [activeStep, setActiveStep] = React.useState(0);
  const [result, setResult] = React.useState(null);
  const [recapSteps, setRecapSteps] = React.useState([]);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedData, setSelectedData] = useState({
    category: "",
    mode: "",
    surface: "",
    tva: "",
  });

  const [category, setCategory] = useState([
    {
      id: "précaire",
      name: "précaire",
      field: "button",
    },
    {
      id: "précaire1",
      name: "précaire1",
      field: "button",
    },
    {
      id: "précaire2",
      name: "précaire2",
      field: "button",
    },
    {
      id: "précaire3",
      name: "précaire3",
      field: "input",
    },
  ]);
  const [mode, setMode] = useState([{}]);
  const [surface, setSurface] = useState([{}]);
  const [tva, setTva] = useState([{}]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 700);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (activeStep === 0) {
      setPrevColor("rgb(184 184 184)");
    } else {
      setPrevColor("#433cbf");
    }
  }, [activeStep]);
  const handleSelectedData = (name, value) => {
    setSelectedData({ ...selectedData, [name]: value });
    if (name !== "tva") setRecapSteps([...recapSteps, value]);
  };

  const handleCategory = (name, value) => {
    setCategory({ ...category, [name]: value });
  };

  const handleMode = (name, value) => {
    setMode({ ...mode, [name]: value });
  };

  const handleSurface = (name, value) => {
    setSurface({ ...surface, [name]: value });
  };

  const handleTva = (name, value) => {
    setTva({ ...tva, [name]: value });
  };
  const [prevColor, setPrevColor] = React.useState("rgb(184 184 184)");
  // const isStepOptional = (step) => {
  //     return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (activeStep < 4) setProgress(progress + 25);
  };
  //console.log(activeStep);
  //console.log(prevColor);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setProgress(progress - 25);
  };

  const [inputValue, setInputValue] = useState(130);
  const handleSubmitCefProject = (e) => {
    e.preventDefault();
    //console.log("Form submitted with input value:", inputValue);
  };
  const [inputValueMoblie, setInputValueMoblie] = useState(130);
  const handleSubmitCefProjectMobile = (e) => {
    e.preventDefault();
    //console.log("Form submitted with input value:", inputValue);
  };

  const handleReset = () => {
    setProgress(0);
    setActiveStep(0);
    setResult(null);
    setSelectedData({ category: "", mode: "", surface: "", tva: "" });
    setcustomFormData({
      nom: "",
      prenom: "",
      surface: "",
    });
    setIsolationData({
      ITE: 0,
      ITECOUT: 0,
      RAMPANTS: 0,
      RAMPANTSCOUT: 0,
      COMBLES: 0,
      COMBLESCOUT: 0,
      TotalIte: 0,
      TotalRamprants: 0,
      TotalCombles: 0,
    });
    setPacChauffePoele({
      pac: 1,
      pacCout: 0,
      chauffe: 1,
      chauffeCout: 0,
      poeleGrannule: 1,
      poeleGrannuleCout: 0,
      totalPac: 0,
      totalRampants: 0,
      TotalCombles: 0,
    });
    setSelectedOptionDropD({
      code: 1000,
      name: "Ain",
    });
    setSelectedOptionDropMain({
      id: 1,
      title: "Précaire",
    });
    setSelectedOptionIChaufageCom({
      id: 1,
      title: "Fioul",
    });
    setSelectedOptionIsolationCom({ id: 1, title: "Aucun" });
    setSelectedOptionIsolationMur({ id: 1, title: "Aucun" });
    setSelectedOptionIsolationPlan({ id: 1, title: "Aucun" });
    setSelectedOptionMiton({
      id: 1,
      title: "non",
    });
    setSelectedOptionMitonTypeFen({
      id: 1,
      title: "Simple vitrage",
    });
    setCompleted(0);
    localStorage.clear();
  };
  const steps = [
    {
      key: "Détails",
      label: "Détails",
    },
    {
      key: "category",
      label: "Détails",
    },
    {
      key: "mode",
      label: "Détails",
    },
    {
      key: "mode",
      label: "Détails",
    },
    {
      key: "mode",
      label: "Détails",
    },
    // {
    //   key: "Total",
    //   label: "Total",
    // },
    // {
    //   key: "tva",
    //   label: "Saisir le TV",
    // },
  ];
  // const steps = ['Choisir une catégorie', 'Choisir un mode de chauffage', 'Choisir une surface', 'Saisir le TV'];
  const useCalculateMutation = useCalculate();
  const [completed, setCompleted] = useState(0);
  // const [cefProget, setCefProget] = useState(110);
  // const [cefProgetTwo, setCefProgetTwo] = useState(43);

  const handleSubmit = async (e) => {
    const payloadData = new FormData();
    payloadData.append("details", JSON.stringify(customFormData));
    payloadData.append("code_postal", selectedOptionDropD.code);
    payloadData.append("precaire", selectedOptionDropMain.id);
    payloadData.append("isolation_chaufage", selectedOptionIChaufageCom.id);
    payloadData.append("isolation_com", selectedOptionIsolationCom.id);
    payloadData.append("isolation_mur", selectedOptionIsolationMur.id);
    payloadData.append("isolation_plan", selectedOptionIsolationPlan.id);
    payloadData.append("miton", selectedOptionMiton.id);
    payloadData.append("miton_fen", selectedOptionMitonTypeFen.id);
    payloadData.append("valo", valorisation);
    payloadData.append("cef_projet", isMobile ? inputValueMoblie : inputValue);
    payloadData.append("isolation_data", JSON.stringify(isolationData));
    payloadData.append("chauffage_data", JSON.stringify(pacChauffePoele));

    try {
      const res = await useCalculateMutation.mutateAsync(payloadData);
      //console.log(res);
      setResult(res);
      setCompleted(1);
      setInputValue(res.cef_projet1);
      setInputValueMoblie(res.cef_projet1);
      handleNext();
    } catch (error) {
      //console.log(error);
    }
  };
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "rgba(67,60,191,1)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "rgba(67,60,191,1)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "rgb(67, 60, 191)",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "rgb(67, 60, 191)",
      zIndex: 1,
      fontSize: 25,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon className="QontoStepIcon-completedIcon" />
        ) : (
          <AdjustIcon style={{ width: "25px" }} />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
    <div
      style={{ marginBottom: "10px", backgroundColor: "rgba(246,246,246,1)" }}
      className={"pr-2 pt-5 pl-2 flex-1  "}
      // height: '75%'
    >
      <Stepper
        orientation={"horizontal"}
        alternativeLabel={isMobile}
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return !completed ? (
            <Step key={index} {...stepProps}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                {...labelProps}
              ></StepLabel>
              {isMobile && <StepContent></StepContent>}
            </Step>
          ) : (
            <></>
          );
        })}
      </Stepper>
      {activeStep > 4 ? (
        <div className={"flex items-center flex-col w-full"}>
          {result && (
            <>
              <div class="flex flex-col mt-8">
                <CustomDialog
                  code={selectedOptionDropD.code}
                  customFormData={customFormData}
                  precaire={selectedOptionDropMain}
                  chauffage={selectedOptionIChaufageCom}
                  result={result}
                  combles={selectedOptionIsolationCom}
                  planchar={selectedOptionIsolationPlan}
                  murs={selectedOptionIsolationMur}
                  fenetre={selectedOptionMitonTypeFen}
                  mitoyenne={selectedOptionMiton}
                  valorisation={valorisation}
                  isolationData={isolationData}
                  setIsolationData={setIsolationData}
                  pacChauffePoele={pacChauffePoele}
                  setPacChauffePoele={setPacChauffePoele}
                />
                <div class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                    <SimpleAccordion
                      inputValueMoblie={inputValueMoblie}
                      setInputValueMoblie={setInputValueMoblie}
                      handleSubmitCefProjectMobile={
                        handleSubmitCefProjectMobile
                      }
                      result={result}
                      isolationData={isolationData}
                      pacChauffePoele={pacChauffePoele}
                    />
                    {/* <CustomList
                      inputValueMoblie={inputValueMoblie}
                      setInputValueMoblie={setInputValueMoblie}
                      handleSubmitCefProjectMobile={
                        handleSubmitCefProjectMobile
                      }
                      result={result}
                      isolationData={isolationData}
                      pacChauffePoele={pacChauffePoele}
                    /> */}
                    {/* <div>
                      <br></br>
                    </div> */}
                    {/* <CustomListTwo
                      inputValueMoblie={inputValueMoblie}
                      setInputValueMoblie={setInputValueMoblie}
                      handleSubmitCefProjectMobile={
                        handleSubmitCefProjectMobile
                      }
                      isolationData={isolationData}
                      result={result}
                      pacChauffePoele={pacChauffePoele}
                    /> */}
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            className={"mt-6"}
            style={{
              display: "flex",
            }}
          >
            <div className="mr-2">
              <Button
                startIcon={<Icon icon="system-uicons:reset-forward" />}
                variant={"outlined"}
                color="inherit"
                onClick={handleReset}
              >
                Actualiser
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <StepsFunction
            handleNext={handleNext}
            handleSelectedData={handleSelectedData}
            selectedData={selectedData}
            index={activeStep}
            formData={formData}
            setFormData={setFormData}
            selectedOptionDropMain={selectedOptionDropMain}
            setSelectedOptionDropMain={setSelectedOptionDropMain}
            selectedOptionDropD={selectedOptionDropD}
            setSelectedOptionDropD={setSelectedOptionDropD}
            selectedOptionMiton={selectedOptionMiton}
            setSelectedOptionMiton={setSelectedOptionMiton}
            selectedOptionMitonTypeFen={selectedOptionMitonTypeFen}
            setSelectedOptionMitonTypeFen={setSelectedOptionMitonTypeFen}
            customFormData={customFormData}
            setcustomFormData={setcustomFormData}
            selectedOptionIsolationMur={selectedOptionIsolationMur}
            setSelectedOptionIsolationMur={setSelectedOptionIsolationMur}
            selectedOptionIsolationPlan={selectedOptionIsolationPlan}
            setSelectedOptionIsolationPlan={setSelectedOptionIsolationPlan}
            selectedOptionIsolationCom={selectedOptionIsolationCom}
            setSelectedOptionIsolationCom={setSelectedOptionIsolationCom}
            selectedOptionIChaufageCom={selectedOptionIChaufageCom}
            setSelectedOptionIChaufageCom={setSelectedOptionIChaufageCom}
            valorisation={valorisation}
            setValorisation={setValorisation}
            isolationData={isolationData}
            setIsolationData={setIsolationData}
            pacChauffePoele={pacChauffePoele}
            setPacChauffePoele={setPacChauffePoele}
          />
          <div className={"flex justify-around"}>
            <Button
              style={{
                backgroundColor: prevColor,
                whiteSpace: "nowrap",
                textAlign: "center",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "19px",
                color: "rgba(255,255,255,1)",
                borderRadius: "10px",
                position: "static",
              }}
              variant={"outlined"}
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
            {activeStep !== 4 && (
              <Button
                style={{
                  backgroundColor: "rgba(67,60,191,1)",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "19px",
                  color: "rgba(255,255,255,1)",
                  borderRadius: "10px",
                  position: "static",
                }}
                disabled={activeStep === 4}
                onClick={handleNext}
                sx={{ mr: 1 }}
              >
                Suivante
              </Button>
            )}

            {activeStep === 4 && (
              <Button
                // disabled={!selectedData}
                style={{
                  backgroundColor: "rgba(67,60,191,1)",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "19px",
                  color: "rgba(255,255,255,1)",
                  borderRadius: "10px",
                  position: "static",
                }}
                onClick={handleSubmit}
              >
                {useCalculateMutation.isLoading && (
                  <CircularProgress sx={{ marginRight: "10px" }} size={15} />
                )}
                {activeStep === 4 ? "Terminer" : ""}
              </Button>
            )}
          </div>
        </React.Fragment>
      )}

      {/*<RecapSteps steps={selectedData}></RecapSteps>*/}
    </div>
  );
}
