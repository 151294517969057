import axiosClient from "./axiosClient";
import { useMutation, useQuery } from "react-query";

const calculate = async (values) => {
  const parsed = await axiosClient.post("calcul", values);
  return parsed.data;
};

const useCalculate = (values) => {
  return useMutation(["calculate"], (values) => calculate(values));
};

const getCodePostal = async () => {
  const parsed = await axiosClient.get("code-postal");
  return parsed.data;
};

const useCodePostal = () => {
  return useQuery(["code-postal"], () => getCodePostal());
};

const getPrecarite = async () => {
  const parsed = await axiosClient.get("precaire");
  return parsed.data;
};

const usePrecarite = () => {
  return useQuery(["precaire"], () => getPrecarite());
};

const getMitoyenne = async () => {
  const parsed = await axiosClient.get("mitoyenne");
  return parsed.data;
};

const useMitoyenne = () => {
  return useQuery(["mitoyenne"], () => getMitoyenne());
};

const getTypefenetre = async () => {
  const parsed = await axiosClient.get("type-fenetre");
  return parsed.data;
};

const useTypefenetre = () => {
  return useQuery(["type-fenetre"], () => getTypefenetre());
};

const getIsolationMur = async () => {
  const parsed = await axiosClient.get("isolation-mur");
  return parsed.data;
};

const useIsolationMur = () => {
  return useQuery(["isolation-mur"], () => getIsolationMur());
};

const getIsolationPlanchar = async () => {
  const parsed = await axiosClient.get("isolation-planchar");
  return parsed.data;
};

const useIsolationPlanchar = () => {
  return useQuery(["isolation-planchar"], () => getIsolationPlanchar());
};

const getIsolationComble = async () => {
  const parsed = await axiosClient.get("isolation-comble");
  return parsed.data;
};

const useIsolationComble = () => {
  return useQuery(["isolation-comble"], () => getIsolationComble());
};

const getChaufage = async () => {
  const parsed = await axiosClient.get("chaufage");
  return parsed.data;
};

const useChaufage = () => {
  return useQuery(["chaufage"], () => getChaufage());
};

export {
  useChaufage,
  useCalculate,
  useCodePostal,
  usePrecarite,
  useMitoyenne,
  useTypefenetre,
  useIsolationMur,
  useIsolationPlanchar,
  useIsolationComble,
};
