import React from "react";
import { useMitoyenne, useTypefenetre } from "../../api/calculeApi";
import Dropdown from "./Dropdown";
import SearchDropdown from "./SearchDropdown";

export default function ListChaufForm({
  selectedOptionMiton,
  setSelectedOptionMiton,
  selectedOptionMitonTypeFen,
  setSelectedOptionMitonTypeFen,
  valorisation,
  setValorisation,
}) {
  const [completed, setCompleted] = React.useState(0);

  const mitoyenne = useMitoyenne();
  const typeFene = useTypefenetre();
  const mitoyenneData = mitoyenne?.data;
  const typeFeneData = typeFene?.data;

  // const [selectedOptionMiton, setSelectedOptionMiton] = React.useState({});
  // const [selectedOptionMitonTypeFen, setSelectedOptionMitonTypeFen] =
  React.useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("mitoyenne", JSON.stringify(selectedOptionMiton));
    localStorage.setItem(
      "typeFene",
      JSON.stringify(selectedOptionMitonTypeFen)
    );
    setCompleted(1);
  };

  return (
    <div className="w-full px-8 pt-6 pb-2 mb-4">
      <form className="px-8 pt-6 pb-2 mb-2" onSubmit={handleSubmit}>
        <div className="flex flex-wrap mb-1 -mx-3">
          <div className="w-full max-w-md px-3 mb-1 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 ml-2 font-bold text-gray-700"
              style={{
                whiteSpace: "nowrap",
                // textAlign: "center",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "20px",
                color: "rgba(74,69,69,1)",
              }}
              htmlFor="Surface"
            >
              Mitoyenneté
            </label>
            {mitoyenne.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionMiton}
                setSelectedOptionDropMain={setSelectedOptionMiton}
                precariteData={mitoyenneData}
              />
            )}
          </div>
          <div className="w-full max-w-md px-3 mb-2 md:w-1/2 md:mb-0">
            <label
              className="block mb-2 ml-2 font-bold text-gray-700"
              style={{
                whiteSpace: "nowrap",
                // textAlign: "center",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "20px",
                color: "rgba(74,69,69,1)",
              }}
              htmlFor="CodePostal"
            >
              Type des fenêtres
            </label>

            {typeFene.isSuccess && (
              <Dropdown
                selectedOptionDropMain={selectedOptionMitonTypeFen}
                setSelectedOptionDropMain={setSelectedOptionMitonTypeFen}
                precariteData={typeFeneData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-wrap mb-2 -mx-3">
          <div className="w-full max-w-md px-3 mb-2 md:w md:mb-0">
            <label
              className="block mb-1 ml-2 font-bold text-gray-700"
              htmlFor="precarite"
              style={{
                whiteSpace: "nowrap",
                // textAlign: "center",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "20px",
                color: "rgba(74,69,69,1)",
              }}
            >
              Valorisation
            </label>
            <input
              id="precarite"
              type="number"
              value={valorisation}
              onChange={(e) => setValorisation(e.target.value)}
              className="inline-flex justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm h-9 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              min={0}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
