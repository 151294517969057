import React, { useEffect, useState } from "react";
import {
  useChaufage,
  useIsolationComble,
  useIsolationMur,
  useIsolationPlanchar,
} from "../api/calculeApi";
// import Dropdown from "./Dropdown";

export default function IsolationForm({ setIsolationData, isolationData }) {
  // const [formData, setFormData] = useState({
  //   system: [],
  //   isolationComb: [],
  //   isolationPlanchar: [],
  // });
  const [completed, setCompleted] = React.useState(0);

  const isolationMur = useIsolationMur();
  const chaufage = useChaufage();
  const isolationPlanchar = useIsolationPlanchar();
  const isolationComble = useIsolationComble();
  const isolationMurData = isolationMur?.data;
  const isolationPlancharData = isolationPlanchar?.data;
  const isolationCombleData = isolationComble?.data;

  const handleChange = (e) => {
    setIsolationData({
      ...isolationData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form className="w-full px-8 pt-6 pb-2 mb-4">
      <div className="flex flex-wrap mb-2 -mx-3">
        <div className="w-full px-3 mb-2 text-center md:w md:mb-0">
          <label
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
            className="block mb-1 font-bold text-gray-700"
            htmlFor="Nom"
          >
            Ite
          </label>
          <div className="flex justify-around">
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="ITE"
              name="ITE"
              type="text"
              placeholder="m²"
              value={isolationData.ITE === 0 ? "" : isolationData.ITE}
              onChange={(e) => handleChange(e)}
            />
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="ITECOUT"
              name="ITECOUT"
              type="text"
              placeholder="P.U €"
              value={isolationData.ITECOUT === 0 ? "" : isolationData.ITECOUT}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-2 -mx-3">
        <div className="w-full px-3 mb-2 text-center md:w md:mb-0">
          <label
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
            className="block mb-1 font-bold text-gray-700"
            htmlFor="Nom"
          >
            Rampants
          </label>
          <div className="flex justify-around">
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="RAMPANTS"
              name="RAMPANTS"
              type="text"
              placeholder="m²"
              value={isolationData.RAMPANTS === 0 ? "" : isolationData.RAMPANTS}
              onChange={(e) => handleChange(e)}
            />
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="RAMPANTSCOUT"
              name="RAMPANTSCOUT"
              type="text"
              placeholder="P.U €"
              value={
                isolationData.RAMPANTSCOUT === 0
                  ? ""
                  : isolationData.RAMPANTSCOUT
              }
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mb-2 -mx-3">
        <div className="w-full px-3 mb-2 text-center md:w md:mb-0">
          <label
            style={{
              whiteSpace: "nowrap",
              // textAlign: "center",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "20px",
              color: "rgba(74,69,69,1)",
            }}
            className="block mb-1 font-bold text-gray-700"
            htmlFor="Nom"
          >
            Combles
          </label>
          <div className="flex justify-around">
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="COMBLES"
              name="COMBLES"
              type="text"
              placeholder="m²"
              value={isolationData.COMBLES === 0 ? "" : isolationData.COMBLES}
              onChange={(e) => handleChange(e)}
            />
            <input
              className="inline-flex justify-between h-12 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-36 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              id="COMBLESCOUT"
              name="COMBLESCOUT"
              type="text"
              placeholder="P.U €"
              value={
                isolationData.COMBLESCOUT === 0 ? "" : isolationData.COMBLESCOUT
              }
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
