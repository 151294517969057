import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./pages/Main";

function App() {
  const [progress, setProgress] = React.useState(0);
  //console.log(progress);
  return (
    <div className="flex flex-col h-screen bg-gray-100 ">
      <Header progress={progress} />
      <Main progress={progress} setProgress={setProgress}></Main>
      <Footer />
    </div>
  );
}

export default App;
