import logo from "./bilel.png";
export default function Footer() {
  return (
    <footer
      className="bottom-0 flex w-full p-4 text-center bg-gray-300 shadow md:p-6"
      style={{
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "rgb(67, 60, 191)",
        // borderBottomLeftRadius: "25px",
        // borderBottomRightRadius: "25px",
      }}
    >
      <img style={{ maxWidth: "40px" }} src={logo} alt="" srcset="" />
    </footer>
  );
}
