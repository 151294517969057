import ProgressBar from "./ProgressBar";

export default function Header({ progress }) {
  return (
    <header>
      <nav
        style={{ backgroundColor: "rgba(67,60,191,1)" }}
        className="bg-gray-300 flex-col flex items-center content-center pt-8  rounded-b-3xl h-40  border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800"
      >
        <div className="flex-col items-center content-center justify-between max-w-screen-xl gap-2 mx-auto mt-3">
          <a className="flex items-center">
            {/*<img src={logo} className="h-6 mr-3 sm:h-9" */}
            {/*     alt="vaerdia Logo"/> */}
            <span
              style={{
                whiteSpace: "nowrap",
                lineHeight: "24px",
                marginTop: "-1px",
                textAlign: "center",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "20px",
                color: "rgba(255,255,255,1)",
              }}
              className="self-center text-xl font-semibold whitespace-nowrap dark:text-white"
            >
              Simulateur Reno globale <br></br> BAR TH 164
            </span>
          </a>
        </div>
        <ProgressBar prog={progress}></ProgressBar>
      </nav>
    </header>
  );
}
