import { useState } from "react";

export default function SearchDropdown({
  selectedOptionDropD,
  setSelectedOptionDropD,
  options,
  codePostalData,
}) {
  // const options = [
  //   "Option 1",
  //   "Option 2",
  //   "Option 3",
  //   "Option 4",
  //   "Option 5",
  //   "Option 6",
  //   "Option 7",
  //   "Option 8",
  // ];

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedOption, setSelectedOption] = useState("");

  const filteredOptions = codePostalData.filter((option) =>
    option.code.toString().includes(searchTerm.toString())
  );

  const handleOptionSelect = (option) => {
    setSelectedOptionDropD(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm h-9 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          id="dropdown-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedOptionDropD?.code || "Choisir une option"}</span>
          <svg
            className="w-5 h-5 ml-2 -mr-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="rgba(0,187,244,1)"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 16a1 1 0 01-.71-.29l-4-4a1 1 0 011.42-1.42L10 13.59l3.29-3.3a1 1 0 011.42 1.42l-4 4A1 1 0 0110 16z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } absolute z-10 mt-1 w-full bg-white shadow-lg`}
      >
        <div className="flex justify-between px-4 py-2">
          <input
            type="text"
            className="w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            style={{ backgroundColor: "#6dc140" }}
            type="button"
            className="px-3 py-1 ml-2 text-sm font-medium text-white border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setSearchTerm("")}
          >
            Clear
          </button>
        </div>
        <div className="overflow-auto max-h-40">
          {filteredOptions.map((option) => (
            <button
              key={option.code}
              type="button"
              className={`${
                selectedOptionDropD?.code === option.code
                  ? "bg-indigo-500 text-white"
                  : "text-gray-700"
              } block w-full px-4 py-2 text-sm text-left hover:bg-indigo-500 hover:text-white`}
              onClick={() => handleOptionSelect(option)}
            >
              {option.code}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
